<template>
  <v-container fluid pa-0>
    <v-row no-gutters class="pa-2">
      <v-col cols="12" md="12">
        <v-tabs v-model="tab" color="blue" slider-size="3">
            <v-tab>Edit Loc Entity </v-tab>
            <v-tab>Associate Meters</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
               <v-card tile>
                <v-card-text>
                 <edit-loc-entity-tree :addoredit="'edit'" :locentitytree="locEntityTree"></edit-loc-entity-tree>
                </v-card-text>
                <v-card-actions align="end" justify="end">
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="onLocEntityTreeSave">Save</v-btn>
                  <v-btn color="primary" @click="onLocEntityTreeCancel">Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card tile>
                <v-card-text>
                <link-loc-entity-meter v-if="locEntityTree.length!= 0" :locentitytree="locEntityTree" :meters="meters"></link-loc-entity-meter>
                </v-card-text>
                <v-card-actions align="end" justify="end">
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="onLinkLocationEntityMetersSave">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EditLocEntityTree from "../components/addOrEdit/EditLocEntityTree.vue";
import LinkLocEntityMeter from "../components/newUser/associateLocEntityMeter/LinkLocEntityMeter";

import { EventBus } from "@/lib/EventBus";

export default {
  components: {
    EditLocEntityTree,
    LinkLocEntityMeter
  },
  data() {
    return {
      tab:0,
      e1: 1,
      locEntityTree: [],
      meters: [],
      addOrEdit:""
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      var self = vm;
      if (self.$router.currentRoute.name == "edit_loc_entity") {
        self.$store.dispatch("MDMStore/getLocEntityTree").then(tree => {
        self.locEntityTree = tree;
        self.$store
          .dispatch({
            type: "MDMStore/getMeters",
            orgId: localStorage.getItem("orgId")
          })
          .then(meterGrps => {
            self.meters = meterGrps.flat();
          });
      });
      }
    });
  },
  computed:{
    // locEntityTree() {
    //   return this.$store.getters["MDMStore/getterLocEntityTree"];
    // }
  },
  mounted() {
    this.addOrEdit = this.$attrs.addOrEdit;
    EventBus.$on("on-edited-loc-entity-saved", () => {
      this.$store.dispatch("MDMStore/getLocEntityTree").then(tree => {
        this.locEntityTree = tree;
        this.$store.commit("MDMStore/SET_SNACKBAR_DETAILS", {text:"Hierarchy updated successfully", status:"success"});
        this.$store.commit("MDMStore/SET_LOC_ENTITY_TREE", tree);
        this.$store
          .dispatch({
            type: "MDMStore/getMeters",
            orgId: localStorage.getItem("orgId")
          })
          .then(meterGrps => {
            this.meters = meterGrps.flat();
            this.e1 = 2;
          });
      });
    });
    // EventBus.$on("on-loc-entity-meter-saved", () => {
    //   this.$store.dispatch({
    //     type: "MDMStore/getOrgDetails",
    //     router: this.$router
    //   });
    //   this.$router.push({
    //     path: "/"
    //   });
    // });
  },
  beforeCreate() {
  },
  created() {
  },
  methods: {
    onLocEntityTreeSave() {
      EventBus.$emit("edited-loc-entity-tree-save");
    },
    onLocEntityTreeCancel(){
       EventBus.$emit("edited-loc-entity-tree-save");
    },
    onLinkLocationEntityMetersSave() {
      EventBus.$emit("loc-entity-meter-save");
    }
  }
};
</script>
