<template>
  <v-container fluid pa-0>
    <v-row no-gutters>
      <v-col>
          <v-card tile class="elevation-0">
        <v-card-title class="secondary">
          <span>{{orgDetails.hasOwnProperty("name") ? orgDetails.name : ""}}</span>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2 elevation-1"
                fab
                dark
                x-small
                color="primary"
                v-on="on"
                @click="onModifyLocEntity({id:generate_UUID(),parentId:null, children:[]}, 'Add')"
              >
                <v-icon dark>add</v-icon>
              </v-btn>
            </template>
            <span>Add location entity</span>
          </v-tooltip>
        </v-card-title>
          <v-card-text>
            <v-treeview v-model="locEntityTreeModel" :items="locEntityTree" item-key="name">
              <template v-slot:prepend="{ item, open }">
                <v-icon>label</v-icon>
              </template>
              <template v-slot:append="{ item, open }">
                <v-tooltip top v-if="item.hasOwnProperty('meters') && item.meters.length == 0">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2 elevation-0"
                      icon
                      color="primary"
                      v-on="on"
                      @click="onModifyLocEntity({id:generate_UUID(),parentId:item.id , children:[]}, 'Add sub')"
                    >
                      <v-icon dark>add</v-icon>
                    </v-btn>
                  </template>
                  <span>Add sub location entity</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                     <v-btn
                      class="mx-2 elevation-0"
                      icon
                      color="primary"
                      v-on="on"
                      @click="onModifyLocEntity(item, 'Edit')"
                    >
                      <v-icon dark>edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit location entity</span>
                </v-tooltip>
                <!-- <v-tooltip top>
                  <template v-if="item.parentId != null" v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2 elevation-0"
                      icon
                      color="primary"
                      v-on="on"
                      @click="onDeleteLocEntity(item)"
                    >
                      <v-icon dark>delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete location entity</span>
                </v-tooltip> -->
              </template>
            </v-treeview>
          </v-card-text>
          <!-- <v-card-actions>
            <v-btn>Add</v-btn>
          </v-card-actions>-->
        </v-card>
      </v-col>
      <v-divider vertical v-if="showAddOrEditview"></v-divider>
      <v-col cols="6" md="6" v-if="showAddOrEditview">
        <v-card tile class="elevation-0">
          <v-card-text class="pa-0">
            <modify-loc-entity :entity="selectedEntity" :addOrEdit="modifyType"></modify-loc-entity>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { EventBus } from "@/lib/EventBus";
import ModifyLocEntity from "../newUser/setupLocEntity/ModifyLocEntity.vue";

export default {
  name: "edit-loc-entity-tree",
  components: {
    ModifyLocEntity
  },
  props: {
    addoredit: {
      type: String
    }
  },
  data() {
    return {
      locEntityTreeModel: [],
      modifyType: "",
      selectedEntity: {},
      showAddOrEditview: false,
      locEntityTree: []
      //  [{"id":"aa444fdd-6f89-483a-9c1d-cc23888790c0","parentId":null,"children":[{"id":"769def23-b1f0-45a9-8d7c-c70cf000602b","parentId":"aa444fdd-6f89-483a-9c1d-cc23888790c0","children":[{"id":"041505d7-04d0-429d-a270-b70e3eb1a539","parentId":"769def23-b1f0-45a9-8d7c-c70cf000602b","children":[],"name":"1.1.1","description":"1.1.1","latitude":5.473,"longitude":15.889,"type":7}],"name":"1.1","description":"1.1","type":7,"latitude":-6.808,"longitude":19.58}],"name":"1","description":"1","type":9,"latitude":5.822,"longitude":18.174},{"id":"6ff24d2b-ef1c-424d-9075-c8de53a66445","parentId":null,"children":[{"id":"a62cff55-0160-46db-9cee-ab251b5e5ce7","parentId":"6ff24d2b-ef1c-424d-9075-c8de53a66445","children":[],"name":"2.1","description":"2.1","type":9,"latitude":25.353,"longitude":35.752}],"name":"2","description":"2","type":8,"latitude":-10.975,"longitude":25.029}]
    };
  },
  watch: {
  },
  mounted() {
    var self = this;
      if (self.$props.addoredit == "edit") {
        self.locEntityTree = self.$store.getters[
          "MDMStore/getterLocEntityTree"
        ];
      }
  },
  created() {
    EventBus.$on("edited-add-loc-entity-save", entity => {
      this.showAddOrEditview = false;
      this.addLocEntity(entity);
    });
    EventBus.$on("edited-edit-loc-entity-save", entity => {
      this.showAddOrEditview = false;
      this.editLocEntity(entity);
    });
    EventBus.$on("edited-add-loc-entity-close", () => {
      this.showAddOrEditview = false;
    });
    EventBus.$on("edited-loc-entity-tree-save", () => {
      this.$store
        .dispatch({
          type: "MDMStore/updateLocEntityTree",
          data: this.locEntityTree
        }).then(result => {
          if (result.data.hasOwnProperty("success") && result.data.success) {
            EventBus.$emit("on-edited-loc-entity-saved");
          } else {
            console.log("Err on-edited-loc-entity-saved");
          }
        });
    });
  },
  computed: {
    orgDetails() {
      return this.$store.getters["MDMStore/getterOrgDetails"];
    }
  },
  methods: {
    onModifyLocEntity(item, operation) {
      Object.assign(item, { org_idx: Number(localStorage.getItem("orgId")), meters:[] });
      this.selectedEntity = Object.assign({}, item);
      this.modifyType = operation;
      this.showAddOrEditview = true;
    },
    onDeleteLocEntity(item){
      this.findAndDeleteEntity(this.locEntityTree, item)
    },
    addLocEntity(entity) {
      if (entity.parentId == null) {
        this.locEntityTree.push(entity);
      } else {
        this.findAndPushEntity(this.locEntityTree, entity);
      }
    },
    editLocEntity(entity) {
      this.findAndUpdateEntity(this.locEntityTree, entity);
    },
    findAndPushEntity(tree, entity) {
      var parentObj = tree.find(({ id }) => id === entity.parentId);
      if (parentObj != undefined && parentObj != null ) {
        if(!parentObj.hasOwnProperty("children")){
          Object.assign(parentObj, {children:[]})
        }
        parentObj.children.push(entity);
      } else {
        for (const subEntity in tree) {
          if (tree[subEntity].hasOwnProperty("children")) {
            this.findAndPushEntity(tree[subEntity].children, entity);
          }
        }
      }
    },
    findAndUpdateEntity(tree, entity) {
      var entityObj = tree.find(({ id }) => id === entity.id);
      if (entityObj != undefined) {
        Object.assign(entityObj, entity);
        // parentObj.children.push(entity);
      } else {
        for (const subEntity in tree) {
          if (tree[subEntity].hasOwnProperty("children")) {
            this.findAndUpdateEntity(tree[subEntity].children, entity);
          }
        }
      }
    },
    findAndDeleteEntity(tree, entity) {
        for (const subEntity in tree) {
          var entityIndex = tree.findIndex(({ id }) => id === entity.id);
          if(entityIndex != -1){
            console.log("Index not found")
          } else {
             this.findAndDeleteEntity(tree[subEntity].children, entity);
          }
        }
    },
    generate_UUID() {
      var dt = new Date().getTime();
      var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function(c) {
          var r = (dt + Math.random() * 16) % 16 | 0;
          dt = Math.floor(dt / 16);
          return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
      return uuid;
    }
  }
};
</script>
  
<style scoped>
</style>


